import cn from "classnames";
import { Card } from "react-bootstrap";
import CountUp from "react-countup";

import { Hexagon } from "@/ui/Hexagon";
import classes from "./styles.module.scss";

export default function ({ className, name, value, prefix, suffix }) {
  return (
    <Card as={Hexagon} alt className={cn(className, classes.StatisticHexagon)}>
      <Card.Body>
        <dl>
          <dt dangerouslySetInnerHTML={{ __html: name }} />
          <dd>
            <CountUp
              end={value}
              prefix={prefix}
              suffix={suffix}
              separator={value > 1999 ? "," : ""}
              enableScrollSpy
            />
          </dd>
        </dl>
      </Card.Body>
    </Card>
  );
}
